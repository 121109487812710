* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 10px;
  line-height: 1.4;
  color: #444;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight: 200;
}


/*
=========================
TYPOGRAPHY
=========================
*/
p, li, ol, ul {
  font-size: 1.6rem;
}



/*
=========================
HEADER
=========================
*/
.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #444;
  padding: 2rem;
  color: #fff;
  text-align: center;
}

.app-header h1 {
  font-size: 3.2rem;
}

.app-header a {
  text-align: center;
  color: white;
  text-decoration: none;
}

main {
  padding-top: 10rem;
}



/*
=========================
BUTTONS
=========================
*/
.btn {
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  background-color: #ddd;
  color: #444;
  border-radius: 5px;
  cursor: pointer;
}



/*
=========================
UTILITIES
=========================
*/
.pad-y {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.mar-r {
  margin-right: 3rem;
}

.mar-r--least {
  margin-right: 1rem;
}

.has-text-center {
  text-align: center;
}

.site-width {
  max-width: 600px;
  margin: 0 auto;
}

.spinner {
  animation: 1000ms linear 0s infinite spinner;
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}