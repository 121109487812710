.list--machines {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.card--machine {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.card--machine:first-child {
  border-top: 1px solid #ccc;
  padding-top: 1rem;
}

.card--machine {
  display: flex;
  align-items: flex-start;
}

.card--image {
  padding-right: 3rem;
  position: relative;
  overflow: hidden;
  height: 100px;
  width: 100px;
}

.card--image img {
  max-width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card--content {

}

.card--content h3 {
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
}

.card--content h3 a {
  text-decoration: none;
  color: #444;
}

.card--content h3 a:hover {
  text-decoration: underline;
}