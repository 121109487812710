.equip-comparison {
  display: flex;
}

.mf-equip,
.wc-equip {
  max-width: 50%;
}

.equip-comparison img {
  display: block;
  max-width: 100%;
}

.equip-comparison h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.singleEquipment--image img {
  display: block;
  max-width: 100%;
}

.singleEquipment--content h2 {
  font-size: 2rem;
}

